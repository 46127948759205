import React, { useState, useEffect } from "react";
import {useSelector,useDispatch} from 'react-redux';
import { addItem  } from "../../redux/action"; 
import Skeleton from "react-loading-skeleton";
import { NavLink, useParams } from "react-router-dom";
import cake1 from"./../../assets/Images/cakes/cake1.jpg";
import cake2 from"./../../assets/Images/cakes/cake2.jpg";
import cake3 from"./../../assets/Images/cakes/cake3.jpg";
import cake4 from"./../../assets/Images/cakes/cake4.jpg";
import cake5 from"./../../assets/Images/cakes/cake5.jpg";
import cake6 from"./../../assets/Images/cakes/cake6.jpg";
import cake7 from"./../../assets/Images/cakes/cake7.jpg";
import cake8 from"./../../assets/Images/cakes/cake8.jpg";
import daily1 from "./../../assets/Images/daily/daily1.jpg";
import daily11 from "./../../assets/Images/daily/daily11.jpg";
import daily2 from "./../../assets/Images/daily/daily2.jpg";
import daily22 from "./../../assets/Images/daily/daily22.jpg";
import daily3 from "./../../assets/Images/daily/daily33.jpg";
import daily33 from "./../../assets/Images/daily/daily3.jpg";
import daily4 from "./../../assets/Images/daily/daily4.jpg";
import daily44 from "./../../assets/Images/daily/daily44.jpg";
import cook1 from "./../../assets/Images/cookies/cook1.jpg";
import cook2 from "./../../assets/Images/cookies/cook2.jpg";
import cook3 from "./../../assets/Images/cookies/cook3.jpg";
import cook4 from "./../../assets/Images/cookies/cook4.jpg";

  import ReactImageMagnify from "react-image-magnify";
  import SpacedSpan from './SpacedSpan.jsx';
  import ReactSlick from './ReactSlick.jsx';
const ProductDetail = () => {
  let id=0;
   id = useParams();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const [allProducts, setAllProducts] = useState([
    {
      id: 1,
      title: "Burger Bun",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "daily",
      image: daily1,
      isDual: true,
      secimage: daily11,

      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 2,
      title: "Potato Bread",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "daily",
      image: daily2,
      isDual: true,
      secimage: daily22,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 3,
      title: "Chocolate Croissant 55 gm",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "daily",
      image: daily3,
      isDual: true,
      secimage: daily33,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 4,
      title: "Milk Bread",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "daily",
      image: daily4,
      isDual: true,
      secimage: daily44,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 5,
      title: "Black Forest Cake - A ",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake1,
      isDual: false,
      secimage: cake1,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 6,
      title: "Choco Dripping Vanilla Cake",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake2,
      isDual: false,
      secimage: cake2,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 7,
      title: "Red Velvet Cake",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake3,
      isDual: false,
      secimage: cake3,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 8,
      title: "Belgian Malted Chocolate Cake",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake4,
      isDual: false,
      secimage: cake4,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 9,
      title: "Toy Train Cake",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake5,
      isDual: false,
      secimage: cake5,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 10,
      title: "Classic White Cake",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake6,
      isDual: false,
      secimage: cake6,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 11,
      title: "Pink Floral Cake-D",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake7,
      isDual: false,
      secimage: cake7,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 12,
      title: "Dark Choco With Violate Texture Cake",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake8,
      isDual: false,
      secimage: cake8,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },

    {
      id: 13,
      title: "Dark Chocolate Biscuit",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cook",
      image: cook1,
      isDual: false,
      secimage: cook1,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 14,
      title: "Crispy Cookies",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cook",
      image: cook2,
      isDual: false,
      secimage: cook2,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 15,
      title: "Chocolate Chip Cookies",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cook",
      image: cook3,
      isDual: false,
      secimage: cook3,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 16,
      title: "Nutty Almond Tiles 150gm",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cook",
      image: cook4,
      isDual: false,
      secimage: cook4,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
  ]);
  const dispatch =useDispatch();

const addProduct=(product)=>{
  dispatch(addItem (product));
}

  const searchParams = useParams();
  const params = new URLSearchParams(window.location.search);
  const [productParam, setProductParam] = useState({
    referenceNumber: params.get("idno"),
  });

  const getProduct = async () => {
    setLoading(true);
    allProducts.map((item)=>{
      if(item?.id===parseInt(productParam?.referenceNumber))
      setProduct(item);

    })
    setLoading(false);

    // setLoading(true);
    // const response = await fetch("http://fakestoreapi.com/products/"+productParam.referenceNumber);
    // setProduct(await response.json());
    // setLoading(false);
  };


  useEffect(() => {
    if (searchParams && searchParams !== null) {
    getProduct();
    }
  }, [searchParams]);

  const addProductCount=(id,numberOfItem)=>{
    allProducts.map((prod)=>{
       if(prod.id===id){
        setProduct((prevState) => ({
          ...prevState,
          "numberOfItem": numberOfItem+1,
        }));
       }
    })
 }
 const deductProductCount=(id,numberOfItem)=>{
  allProducts.map((prod)=>{
    if(parseInt(numberOfItem) >1){
     if(prod.id===id){
      setProduct((prevState) => ({
        ...prevState,
        "numberOfItem": numberOfItem-1,
      }));
     }
    }
  })
}
  const Loading = () => {
    return (
    <>
    <div className="col-md-6">
    <Skeleton height={400}/>
    </div>
    <div className="col-md-6" style={{lineHeight:2}}>
    <Skeleton height={50} width={300}/>
    <Skeleton height={75}/>
    <Skeleton height={25} width={150}/>
    <Skeleton height={50}/>
    <Skeleton height={150}/>
    <Skeleton height={50} width={100}/>
    <Skeleton height={50} width={100} style={{marginLeft:6}}/>
    </div>
    </>
    );
  };
  const ShowProduct = () => {
      return(
    <>
    <div className="row">
    <div className="col-md-1"></div>
      {/* <div className="col-md-4">
        <img className="custom"
          src={product.image}
          alt={product.title}
          height="400px"
          width="400px"
        />
      </div> */}
      <div className="fluid col-md-4">
                <div className="fluid__image-container">
                    <ReactImageMagnify {...{
                        smallImage: {
                            alt: 'Wristwatch by Ted Baker London',
                            isFluidWidth: true,
                            src: product.image,
                        },
                        largeImage: {
                            src: product.image,
                            width: 1200,
                            height: 1800
                        }
                    }} />
                </div>
                <div style={{height: '500px'}} />
            </div>


      <div className="col-md-7 float-left">
        <h6 className="text-uppercase text-black-50">{product.category}</h6>
        <h2 className="fw-bold">{product.title}</h2>
        <p className="lead">
          Rating <span className="text-warning">{product.rating && product.rating.rate}  <i className="fa fa-star"></i></span>
         
        </p>
        <h4 className="my-2">{product.price} Birr</h4>
        <p className="lead">{product.description}</p>

        <button onClick={()=>deductProductCount(product.id,product.numberOfItem)} 
                      className="btn m-0 p-0 count-prod-btn"

                      style={{ width: "5%", height: "4%",fontSize:"16px",backgroundColor:"#efeff5" }}>

                      -              
                            </button>
                    <span className="fw-bold mx-1">{product.numberOfItem}</span>
        <button onClick={()=>addProductCount(product.id,product.numberOfItem)}
                      className="btn m-0 p-0 count-prod-btn"
                      style={{ width: "5%", height: "4%",fontSize:"16px",backgroundColor:"#efeff5" }}>
                      +
                    </button>
                    <button
                      className="btn add-prod-btn px-4 py-2 mx-2 border"
                      onClick={() => addProduct(product)}
                    >
                      {" "}
                      Add to Cart
                    </button>











      </div>
      </div>
    </>
    );
  };
  return (
    <>
      <div className="container py-5">
        <div className="row py-4">{loading ? <Loading /> : <ShowProduct />}</div>
      </div>


            {/* <div className="fluid">
                <div className="fluid__image-container">
                    <ReactImageMagnify {...{
                        smallImage: {
                            alt: 'Wristwatch by Ted Baker London',
                            isFluidWidth: true,
                            src: imggg1,
                        },
                        largeImage: {
                            src: imggg1,
                            width: 1200,
                            height: 1800
                        }
                    }} />
                </div>
                <div className="fluid__instructions">
                    <h3>Basic Example</h3>
                    <p>
                        Side by Side enlargement for mouse input.
                    </p>
                    <p>
                        In place enlargement for touch input.
                    </p>
                    <p>
                        Fluid between breakpoints.
                    </p>
                    <p>
                        Please see
                        <SpacedSpan>
                            <a href="https://github.com/ethanselzer/react-image-magnify/blob/master/example/src/components/Basic.js#L15-L26">
                                source code
                            </a>
                        </SpacedSpan>
                        for details.
                    </p>
                </div>
                <div style={{height: '500px'}} />
            </div> */}


{/* <div className="fluid react-slick">
                <div className="fluid__image-container">
                    <ReactSlick {...{
                        rimProps: {
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false,
                            enlargedImagePosition: 'over'
                        }
                    }} />
                </div>

                <div style={{height: '1000px'}} />
            </div> */}

    </>
  );
};

export default ProductDetail;
