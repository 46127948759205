import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import FmdGoodIcon from '@mui/icons-material/FmdGoodOutlined';
import LockIcon from '@mui/icons-material/LockOutlined';
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useState,useEffect } from 'react';

export default function     Checkout() {
    const state = useSelector((state)=> state.handleCart)
    const [totalPrice, setTotalPrice]=useState(0);
    const [totalCount, setTotalCount]=useState(0);
    let prices=0;
    let countt=0;
    const setTotalProduct=()=>{
      state.map((stat)=>{
        prices=prices+ (stat.numberOfItem*parseInt(stat.price))
        countt=countt+ stat.numberOfItem;
      })
      setTotalPrice(prices)
      setTotalCount(countt)

    }
    useEffect(() => {
      setTotalProduct();
    }, []);
  return (
<>
<div className='container'>
    <h4 className='m-3'>Start Order</h4>
<hr/>

<div className="row">
    <div className="col-md-7">
    <h5><FmdGoodIcon/>Shipping Address</h5>
<Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1 },
      }}
      noValidate
      autoComplete="off"
      className='m-3'
    >
        <small><LockIcon style={{fontSize:"15px"}}/>Your personal information is encrypted and will only be used for delivery purposes</small>
      <div  className='row'>
      <TextField size='small' id="outlined-basic" className='col-md-5 mx-1' label="First Name" variant="outlined" />
      <TextField size='small' id="outlined-basic" className='col-md-5 mx-0' label="Last Name" variant="outlined" />
      </div>
      <div  className='row'>
      <TextField size='small' id="outlined-basic" className='col-md-10' label="Phone Number" variant="outlined" />
      <TextField size='small' id="outlined-basic" className='col-md-10' label="Street Address" variant="outlined" />
      </div>
      <div  className='row' style={{marginLeft:"-4px"}}>
      <TextField size='small' id="outlined-basic" value="Ethiopia" className='col-md-4 mx-0' label="Country" variant="outlined" />
      <TextField size='small' id="outlined-basic" value="Addis Ababa" className='col-md-3 mx-1' label="City" variant="outlined" />
      <TextField size='small' id="outlined-basic" className='col-md-3 mx-0' label="Sub City" variant="outlined" />

      </div>
      <div  className='row'>
      <TextField size='small' id="outlined-basic" className='col-md-10' label="Postcode" variant="outlined" />
        </div>
        <Button variant="contained" size="medium" color="warning" className='m-2'>
          Place Order
        </Button>
    </Box>
    </div>
    <div className="col-md-5">
    <Card sx={{ minWidth: 275 }}>
      <CardContent>

       <h5>        Order Summary({totalCount} items)</h5>
        <hr/>
        <Typography sx={{ mb: 1.5, fontSize:"14px",marginRight:"30px" }} color="text.secondary">
          Totatal Price                   <span style={{float:"right"}}>{totalPrice} Birr</span>
        </Typography>

        <h6 className='my-2'>Protections for this orders</h6>

        <Typography variant="body2">
          <GppGoodOutlinedIcon style={{fontSize:"17px"}}/> Secure Payments
        </Typography>
        <Typography className='my-2' style={{marginLeft:"20px",marginRight:"20px",fontSize:"13px"}}>Every payments you make on our site is secured with strict SSL encryption and PCI DSS data protection protocols.</Typography>
        <Typography variant="body2">
         <SettingsBackupRestoreOutlinedIcon style={{fontSize:"17px"}}/> Refund Policy
        </Typography>
        <Typography className='my-2' style={{marginLeft:"20px",marginRight:"20px",fontSize:"13px"}}>Claim a refund if your order doesn't ship, is missing or arrived with issues.</Typography>
      </CardContent>

    </Card>
    </div>
</div>
<br/>

</div>

</>
  );
}