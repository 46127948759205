import React, { useState, useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { NavLink, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addItem } from "../../redux/action";
import cake1 from "./../../assets/Images/cakes/cake1.jpg";
import cake2 from "./../../assets/Images/cakes/cake2.jpg";
import cake3 from "./../../assets/Images/cakes/cake3.jpg";
import cake4 from "./../../assets/Images/cakes/cake4.jpg";
import cake5 from "./../../assets/Images/cakes/cake5.jpg";
import cake6 from "./../../assets/Images/cakes/cake6.jpg";
import cake7 from "./../../assets/Images/cakes/cake7.jpg";
import standcake from "./../../assets/Images/cakes/standcake.jpg";
import photocake from "./../../assets/Images/cakes/photocake.jpg";
import cake8 from "./../../assets/Images/cakes/cake8.jpg";
import daily1 from "./../../assets/Images/daily/daily1.jpg";
import daily11 from "./../../assets/Images/daily/daily11.jpg";
import daily2 from "./../../assets/Images/daily/daily2.jpg";
import daily22 from "./../../assets/Images/daily/daily22.jpg";
import daily3 from "./../../assets/Images/daily/daily33.jpg";
import daily33 from "./../../assets/Images/daily/daily3.jpg";
import daily4 from "./../../assets/Images/daily/daily4.jpg";
import daily44 from "./../../assets/Images/daily/daily44.jpg";
import cook1 from "./../../assets/Images/cookies/cook1.jpg";
import cook2 from "./../../assets/Images/cookies/cook2.jpg";
import cook3 from "./../../assets/Images/cookies/cook3.jpg";
import cook4 from "./../../assets/Images/cookies/cook4.jpg";
import gal1 from "./../../assets/Images/gallery/gal1.webp";
import gal2 from "./../../assets/Images/gallery/gal2.webp";
import gal3 from "./../../assets/Images/gallery/gal3.webp";
import gal4 from "./../../assets/Images/gallery/gal4.webp";
import gal5 from "./../../assets/Images/gallery/gal5.webp";
import gal6 from "./../../assets/Images/gallery/gal6.webp";
import gal7 from "./../../assets/Images/gallery/gal7.webp";
import gal8 from "./../../assets/Images/gallery/gal8.webp";
import gal9 from "./../../assets/Images/gallery/gal9.webp";
import gal10 from "./../../assets/Images/gallery/gal10.webp";
import display from "./../../assets/Images/display.webp";
import styled from "styled-components";
import Desserts from "../product/Desserts";
import TrendingFlatOutlinedIcon from '@mui/icons-material/TrendingFlatOutlined';
import "./productStyle.css";
import Slider from "react-slick";
import AddIcon from '@mui/icons-material/Add';
import MinimizeIcon from '@mui/icons-material/Minimize';
const Container = styled.section`
  position: relative;
  overflow: hidden;
  display: block;
  padding: 50px;
`;

const Image = styled.img.attrs((props) => ({
  src: props.source,
}))``;

const Target = styled(Image)`
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  opacity: ${(props) => props.opacity};
`;
const ImageToggleOnMouseOver = ({primaryImg, secondaryImg}) => {
  const imageRef = useRef(null);

  return (
    <img 
      onMouseOver={() => {
        imageRef.current.src = secondaryImg;
      }}
      onMouseOut={() => {
        imageRef.current.src= primaryImg;
      }}
      src={primaryImg} 
      alt=""
      ref={imageRef}
      height="250px"
      width="100%"

    />
  )
}

const Products = () => {
  const [data, setData] = useState([]);
  const [paramData, setParamData] = useState("null");
  const [loading, setLoading] = useState(false);
  let componentMounted = true;
  const [allProducts, setAllProducts] = useState([
    {
      id: 1,
      title: "Burger Bun",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "daily",
      image: daily1,
      isDual: true,
      secimage: daily11,

      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 2,
      title: "Potato Bread",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "daily",
      image: daily2,
      isDual: true,
      secimage: daily22,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 3,
      title: "Chocolate Croissant 55 gm",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "daily",
      image: daily3,
      isDual: true,
      secimage: daily33,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 4,
      title: "Milk Bread",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "daily",
      image: daily4,
      isDual: true,
      secimage: daily44,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 5,
      title: "Black Forest Cake - A ",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake1,
      isDual: false,
      secimage: cake1,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 6,
      title: "Choco Dripping Vanilla Cake",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake2,
      isDual: false,
      secimage: cake2,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 7,
      title: "Red Velvet Cake",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake3,
      isDual: false,
      secimage: cake3,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 8,
      title: "Belgian Malted Chocolate Cake",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake4,
      isDual: false,
      secimage: cake4,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 9,
      title: "Toy Train Cake",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake5,
      isDual: false,
      secimage: cake5,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 10,
      title: "Classic White Cake",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake6,
      isDual: false,
      secimage: cake6,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 11,
      title: "Pink Floral Cake-D",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake7,
      isDual: false,
      secimage: cake7,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 12,
      title: "Dark Choco With Violate Texture Cake",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake8,
      isDual: false,
      secimage: cake8,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },

    {
      id: 13,
      title: "Dark Chocolate Biscuit",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cook",
      image: cook1,
      isDual: false,
      secimage: cook1,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 14,
      title: "Crispy Cookies",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cook",
      image: cook2,
      isDual: false,
      secimage: cook2,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 15,
      title: "Chocolate Chip Cookies",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cook",
      image: cook3,
      isDual: false,
      secimage: cook3,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 16,
      title: "Nutty Almond Tiles 150gm",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cook",
      image: cook4,
      isDual: false,
      secimage: cook4,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
  ]);


  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [filter, setFilter] = useState(allProducts);
  const dispatch = useDispatch();

  const addProduct = (product) => {
    dispatch(addItem(product));
  };

  const sourceRef = useRef(null);
  const targetRef = useRef(null);
  const containerRef = useRef(null);

  const [opacity, setOpacity] = useState(0);
  const [offset, setOffset] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };

  const handleMouseMove = (e) => {
    const targetRect = targetRef.current.getBoundingClientRect();
    const sourceRect = sourceRef.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();

  };

  useEffect(() => {
    const getProducts = async () => {
      // setLoading(true);
      // const response = await fetch("https://fakestoreapi.com/products");
      // if (componentMounted) {
      //   setData(await response.clone().json());
      // //   setFilter(await response.json());
      //   setLoading(false);
      // }
      return () => {
        componentMounted = false;
      };
    };
    getProducts();
    filterProduct("daily");
  }, []);

  const Loading = () => {
    return (
      <>
        <div className="col-md-3">
          <Skeleton height={350} />
        </div>
      </>
    );
  };

  const filterProduct = (cat) => {
setParamData(cat);
    const updatedList = allProducts.filter((x) => x.category === cat);
    setFilter(updatedList);
  };
  const addProductCount = (id, numberOfItem, index) => {
    filter.map((prod) => {
        if (prod.id === id) {
          const updatedFlight = [...filter];
          updatedFlight[index].numberOfItem = numberOfItem + 1;
          setFilter(updatedFlight);
      }

    });
  };
  const deductProductCount = (id, numberOfItem, index) => {
    filter.map((prod) => {
      if (parseInt(numberOfItem) > 1) {
        if (prod.id === id) {
          const updatedFlight = [...filter];
          updatedFlight[index].numberOfItem = numberOfItem - 1;
          setFilter(updatedFlight);
        }
      }
    });
  };

  const ShowProducts = () => {
    return (
      <>
        <div className="home-page-tab buttons d-flex justify-content-center mb-3 pb-3">
          <ul>
            <li>
          
              <label
                className= {paramData==="daily"?"linkUnderlined link-tabs":" link-tabs"}
                onClick={() => filterProduct("daily")}
              >Daily Needs</label>
            </li>
            <li>
           
              <label 
                className= {paramData==="cake"?"linkUnderlined link-tabs":"link-tabs"}
                onClick={() => filterProduct("cake")}>Cakes</label>
            </li>
            <li>
              
              <label
                className= {paramData==="cook"?"linkUnderlined link-tabs":" link-tabs"}
                onClick={() => filterProduct("cook")}>Cookies</label>
            </li>
          </ul>
        </div>
        {filter.map((product, index) => {
          return (
            <>
              <div className="col-md-3 mb-4">
                <div
                  className="card h-100 text-center border-light"
                  key={product.id}
                >
                  <NavLink to={`/product?idno=${product.id}`}>
<div>
      <ImageToggleOnMouseOver
        primaryImg={product.image}
        secondaryImg={product.secimage}
        style={{ margin: "0px", padding: "0px" }}
        height="250px"

        alt="" />
    </div>
                  </NavLink>
                  <div style={{ backgroundColor: "green", color: "white" }}>
                    <span>Next Day Delivery</span>
                  </div>
                  <div className="card-body">
                    <p>{product.title}</p>
                    <h5 className="card-text">{product.price} Birr</h5>

                    <button
                      onClick={() =>
                        deductProductCount(
                          product.id,
                          product.numberOfItem,
                          index
                        )
                      }
                      className="btn m-0 p-0 count-prod-btn"

                      style={{ width: "10%", height: "22%",fontSize:"16px",backgroundColor:"#efeff5" }}>

                      -              
                            </button>
                    <span className="fw-bold mx-1">{product.numberOfItem}</span>
                    <button
                      onClick={() =>
                        addProductCount(product.id, product.numberOfItem, index)
                      }
                      className="btn m-0 p-0 count-prod-btn"
                      style={{ width: "10%", height: "22%",fontSize:"16px",backgroundColor:"#efeff5" }}>
                      +
                    </button>
                    <button
                      className="btn add-prod-btn px-4 py-2 mx-2 border"
                      onClick={() => addProduct(product)}
                    >
                      {" "}
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </>
    );
  };
  return (
    <div className="m-0">
      <div className="container mb-0 pt-5">
        <div className="row justify-content-center">
          {<ShowProducts />}
          <div class="hover01 column my-5">
            <div className="row">
              <div className="col-md-7">
                <figure>
                  <img src={standcake}   style={{  width: "80%", height: "auto"}}/>
                </figure>
                {/* <span>Hover</span> */}
              </div>
              <div className="col-md-5">
                <figure>
                  <img src={photocake}   style={{  width: "80%", height: "auto"}}/>
                </figure>
                {/* <span>Hover</span> */}
              </div>
            </div>
          </div>

          <div className="home-page-tab buttons d-flex justify-content-left">
          <ul>
            <li>
          
              <label
                className="linkUnderlined link-tabs"
              >
               Best Selling Customized Cake
              </label>
            </li>
            </ul>
          </div>
          <Desserts/>
        </div>
      </div>

      <div style={{backgroundColor:"#444444"}} className="mt-0 m-0">
<div className="row m-0">
  <div className="col-md-2"></div>
  <div className="col-md-5">

  <img src={display} className="img-responsive my-5 pt-2"  style={{  width: "90%", height: "auto"}}/>

  </div>

  <div className="col-md-3 text-center">


<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>

<section >
<h4 className="text-white">
    Opens Daily - 7:00AM - 11:00PM
    </h4>
    <h2 className="text-white">
Freshly baked Everyday
    </h2>

    <button type="button" class="btn btn-success btn-lg">VISIT OUR STORE <TrendingFlatOutlinedIcon/></button>

</section>
  </div>
  <div className="col-md-2"></div>


</div>
      </div>

      <div className="container mb-0 pt-5">

      <div className="home-page-tab buttons d-flex justify-content-left">
          <ul>
            <li>
          
              <label
                className="linkUnderlined link-tabs"
              >
               Our Gallery
              </label>
            </li>
            </ul>
          </div>

          <div className="slider-container hover01 column">
      <Slider {...settings}>
        <div className="hover01 column">
        <img src={gal1} width={280} />
        </div>
        <div>
        <img src={gal2} width={280} />
        </div>
        <div>
        <img src={gal3} width={280} />
        </div>
        <div>
        <img src={gal4} width={280} />
        </div>
        <div>
        <img src={gal5} width={280} />
        </div>
        <div>
        <img src={gal6} width={280} />
        </div>
        <div>
        <img src={gal7} width={280} />
        </div>
        <div>
        <img src={gal8} width={280} />
        </div>
        <div>
        <img src={gal9} width={280} />
        </div>
        <div>
        <img src={gal10} width={280} />
        </div>
      </Slider>
      <br/>
      <br/>
      <br/>
    </div>

        </div>
    </div>
  );
};

export default Products;
