import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import About from './components/About';
import Home from './components/home/Index';
import Products from './components/product/Products';
import './App.css';
import Product from './components/product/ProductDetail';
import EvisaRouter from './components/common/header/EvisaRouter';
class  App extends React.Component {
  render(){
  return (
    <div className="App">
<EvisaRouter />


    </div>
  );
}
}
export default App;
