import React from "react";
import Products from "../product/Products";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IMG from "../../assets/Images/img1.png";
import IMG2 from "../../assets/Images/img2.png";
import "@coreui/coreui/dist/css/coreui.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { CCarousel, CCarouselItem, CImage } from "@coreui/react";

import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ButtonBase from "@mui/material/ButtonBase";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Margin } from "@mui/icons-material";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
const Index = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <div className="slider-container">
        <CCarousel controls dark>
          <CCarouselItem>
            <CImage className="d-block w-100" src={IMG} alt="slide 1" />
          </CCarouselItem>
          <CCarouselItem>
            <CImage className="d-block w-100" src={IMG2} alt="slide 2" />
          </CCarouselItem>
        </CCarousel>
      </div>
      <Paper
        sx={{
          p: 2,
          margin: "auto",
          maxWidth: 1000,
          flexGrow: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        }}
        className=" my-3 border-0" style={{    borderBottom: "none"}}
      >
        <Grid container>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <div className="row">
                  <div className="col-md-2">
                    <InfoOutlinedIcon sx={{ fontSize: 40 }} />
                  </div>
                  <div className="col-md-10">
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      component="div"
                    >
                      <b>No Added Preservatives </b>
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Premium Ingredients{" "}
                    </Typography>{" "}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <div className="row">
                  <div className="col-md-2">
                    <i class="fa fa-heartbeat" style={{ fontSize: "40px" }}></i>{" "}
                  </div>
                  <div className="col-md-10">
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      component="div"
                    >
                      <b>Freshly Baked </b>
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Everyday{" "}
                    </Typography>{" "}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <div className="row">
                  <div className="col-md-2">
                    <PaymentOutlinedIcon sx={{ fontSize: 40 }} />
                  </div>
                  <div className="col-md-10">
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      component="div"
                    >
                      <b>100% Secure Payment </b>
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      We ensure secure payment!{" "}
                    </Typography>{" "}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Products />
    </>
  );
};

export default Index;
