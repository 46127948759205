import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {delItem} from '../redux/action/index'
import { NavLink,Link } from 'react-router-dom'


  import imggg1 from "./../assets/Images/cakes/cake1.jpg"
  import imggg2 from "./../assets/Images/cakes/cake2.jpg"
  import ReactImageMagnify from "react-image-magnify";
  import SpacedSpan from './product/SpacedSpan.jsx';
  import ReactSlick from './product/ReactSlick.jsx';

import './product/styles.css';


const Cart = () => {
    const state = useSelector((state)=> state.handleCart)
    const dispatch = useDispatch()

    const handleClose = (item) => {
        dispatch(delItem(item))
    }

    const cartItems = (cartItem) => {
        return(

    <tr>
      <td> <img src={cartItem.image} alt={cartItem.title} height="80px" width="100px" /></td>
      <td>{cartItem.price} Birr</td>
      <td>{cartItem.numberOfItem}</td>
      <td>{cartItem.price*cartItem.numberOfItem}</td>
      <td><button onClick={()=>handleClose(cartItem)} className="float-end btn btn-danger m-0 p-0" style={{borderRadius:"25px",width:"30px",height:"30px"}} ><span style={{fontSize:"13px",color:"white"}} className='mt-3 pt-3'>X</span></button>
</td>
    </tr>



        );
    }

    const emptyCart = () => {
        return (
            <div className="px-4 my-5 bg-light rounded-3 py-5">
                <div className="container py-4">
                    <div className="row">
                        <h3>Your Cart is Empty</h3>
                    </div>
                    </div>
                </div>
        );
    }

    const button = () => {
        return(
            <div className="container">
                <div className="row">
                    <NavLink to="/checkout" className="btn btn-outline-primary mb-5 w-25 mx-auto">Proceed To checkout</NavLink>
                </div>
            </div>
        );
    }

    return (
        <>
            {state&&state.length === 0 && emptyCart()}

            {state&&state.length !== 0?
                        <div className="px-4 my-5 bg-light rounded-3">
                        <div className="container py-4">
                    <table class="table">
          <thead>
            <tr>
              <th scope="col">Product</th>
              <th scope="col">Price</th>
              <th scope="col">Quantity</th>
              <th scope="col">Total Price</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
          {state&&state.length !== 0 && state.map(cartItems)}
          </tbody>
        
        </table>
        </div>
        </div>
        :null
        }
            
            {state&&state.length !== 0 && button()}

        </>
    )
}

export default Cart