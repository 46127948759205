import { createStore } from "@reduxjs/toolkit";
import rootReducers from "./index";


const Store = createStore(
    rootReducers,
    {},
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  

  export default Store;
