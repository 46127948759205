import React from "react";
import { Link } from "react-router-dom";
import "./../../assets/css/footer.css";
import expres from './../../assets/Images/footer-logo/express.png'
import diners from './../../assets/Images/footer-logo/diners.png'
import alipay from './../../assets/Images/footer-logo/alipay.png'
import gpay from './../../assets/Images/footer-logo/gpay.png'
import mastercard from './../../assets/Images/footer-logo/mastercard.png'
import masterpass from './../../assets/Images/footer-logo/masterpass.png'
import unionpay from './../../assets/Images/footer-logo/unionpay.png'
import visa from './../../assets/Images/footer-logo/visa.png'
import wechat from './../../assets/Images/footer-logo/wechat.png'
import LogoFooter from './../../assets/Images/footer-logo/logo-footer.png'

const Footer = () => {
  return (
    <>
      <footer className="main-footer m-0" role="contentinfo">
        <div className="footer-top p-y-2">
          <div className="container-fluid"></div>
        </div>
        <div className="footer-middle">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="footer-pad">
                <img src={LogoFooter} alt="Logo Footer" width="100%"/>
                  <br/>
                  <p>
                  NOODO BAKER'S is an award-winning bakery brand. We pride ourselves on making real food from natural 
                  and premium quality ingredients and say "NO to Preservatives and Synthetic ingredients”.
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="footer-pad">
                  <h4 className="footer-title">Customer Service</h4>
                  <ul className="list-unstyled">
                    <li>
                      <Link className="footer-link" to="/FAQs">
                      FAQs
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-link" to="/Track-Order">
                      Track Order
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-link" to="/Reward-Points">
                      Reward Points
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-link" to="/Make-Payments">
                        {" "}
                        Make Payments
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="footer-pad">
                  <h4 className="footer-title">Quick Links</h4>
                  <ul className="list-unstyled">
                    <li>
                      <Link className="footer-link" to="/Create-A-Cake">
                      Create A Cake
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-link" to="/Corporate-Catering">
                      Corporate Catering
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-link" to="/Corporate-Catering">
                      Corporate Catering
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-link" to="/Career">
                      Career
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="footer-pad">
                  <h4 className="footer-title">About Us</h4>
                  <ul className="list-unstyled">
                    <li>
                      <Link className="footer-link" to="/Our-Story">
                      Our Story
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-link" to="/Terms-Conditions">
                      Terms & Conditions
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-link" to="/Refund-Return">
                      Refund & Return
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-link" to="/Refund-Return">
                      Refund & Return
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="evisa-footer-logo text-center justify-content-center">
          <img src={expres} alt="American Express" />
            <img src={diners} alt="Diners Club International" />
            <img src={mastercard} alt="MasterCard" />
            <img src={masterpass} alt="Masters pass" />
            <img src={visa} alt="VISA" />
            <img src={unionpay} alt="Union Pay" />
            <img src={gpay} alt="Google Pay" />
            <img src={alipay} alt="AliPay" />
            <img src={wechat} alt="WeChat Pay" />
        </div>

        <div className="footer-bottom">
          <hr className="footer-break"/>
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-xs-12 text-center float-center">
              <p className="text-xs-center d-inline">
              &copy; {new Date().getFullYear()} All Rights Reserved By Noodo Baker's ltd.
             </p>
              </div>



            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
