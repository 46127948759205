import { type } from "@testing-library/user-event/dist/type"

//for add cart 
export const addItem = (product)=>{
return{
     type:"ADDITEM",
payload:product
}
}

//for del cart
export const delItem = (product)=>{
    return{
         type:"DELITEM",
    payload:product
    }
    }