import React, { useState, useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector, useDispatch } from "react-redux";
import { addItem } from "../../redux/action";
import { NavLink } from "react-router-dom";
import cake1 from"./../../assets/Images/cakes/cake1.jpg";
import cake2 from"./../../assets/Images/cakes/cake2.jpg";
import cake3 from"./../../assets/Images/cakes/cake3.jpg";
import cake4 from"./../../assets/Images/cakes/cake4.jpg";
import cake5 from"./../../assets/Images/cakes/cake5.jpg";
import cake6 from"./../../assets/Images/cakes/cake6.jpg";
import cake7 from"./../../assets/Images/cakes/cake7.jpg";
import cake8 from"./../../assets/Images/cakes/cake8.jpg";
import styled from "styled-components";

import "./productStyle.css";

const Container = styled.section`
  position: relative;
  overflow: hidden;
  display: block;
  padding: 50px;
`;

const Image = styled.img.attrs((props) => ({
  src: props.source,
}))``;

const Target = styled(Image)`
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  opacity: ${(props) => props.opacity};
`;

const Desserts = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  let componentMounted = true;
  const [allProducts, setAllProducts] = useState([
    {
      id: 5,
      title: "Black Forest Cake - A ",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake1,
      isDual: false,
      secimage: cake1,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 6,
      title: "Choco Dripping Vanilla Cake",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake2,
      isDual: false,
      secimage: cake2,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 7,
      title: "Red Velvet Cake",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake3,
      isDual: false,
      secimage: cake3,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 8,
      title: "Belgian Malted Chocolate Cake",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake4,
      isDual: false,
      secimage: cake4,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 9,
      title: "Toy Train Cake",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake5,
      isDual: false,
      secimage: cake5,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 10,
      title: "Classic White Cake",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake6,
      isDual: false,
      secimage: cake6,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 11,
      title: "Pink Floral Cake-D",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake7,
      isDual: false,
      secimage: cake7,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },
    {
      id: 12,
      title: "Dark Choco With Violate Texture Cake",
      price: 695,
      numberOfItem: 1,
      description:
        "From our Legends Collection, the Naga was inspired by the mythical water dragon that protects the ocean's pearl. Wear facing inward to be bestowed with love and abundance, or outward for protection.",
      category: "cake",
      image: cake8,
      isDual: false,
      secimage: cake8,
      rating: {
        rate: 4.6,
        count: 400,
      },
    },



]);


  const [filter, setFilter] = useState(allProducts);
  const sourceRef = useRef(null);
  const targetRef = useRef(null);
  const containerRef = useRef(null);
  const dispatch = useDispatch();

  const addProduct = (product) => {
    dispatch(addItem(product));
  };
  const [opacity, setOpacity] = useState(0);
  const [offset, setOffset] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };

  const handleMouseMove = (e) => {
    const targetRect = targetRef.current.getBoundingClientRect();
    const sourceRect = sourceRef.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();
  };
  useEffect(() => {
    const getProducts = async () => {
      // setLoading(true);
      // const response = await fetch("https://fakestoreapi.com/products");
      // if (componentMounted) {
      //   setData(await response.clone().json());
      // //   setFilter(await response.json());
      //   setLoading(false);
      // }
      return () => {
        componentMounted = false;
      };
    };
    getProducts();
  }, []);

 const Loading=()=>{
     return(
         <>
          <div className="col-md-3">
              <Skeleton height={350}/>
          </div>
         </>
     );
 }
 
 const addProductCount = (id, numberOfItem, index) => {
  allProducts.map((prod) => {
    if (prod.id === id) {

      const updatedFlight = [...allProducts];
      updatedFlight[index].numberOfItem = numberOfItem + 1;
      setAllProducts(updatedFlight);

      //  setAllProducts((prevState) => ({
      //    ...prevState,
      //    " allProducts[index].numberOfItem": numberOfItem+1,
      //  }));
    }
  });
};
 const deductProductCount = (id, numberOfItem, index) => {
  allProducts.map((prod) => {
    if (parseInt(numberOfItem) > 1) {
      if (prod.id === id) {
        const updatedFlight = [...allProducts];
        updatedFlight[index].numberOfItem = numberOfItem - 1;
        setAllProducts(updatedFlight);

        //   setAllProducts((prevState) => ({
        //     ...prevState,
        //     " allProducts[index].numberOfItem": numberOfItem-1,
        //   }));
      }
    }
  });
};
 
const filterProduct=(cat)=>{
    const updatedList= allProducts.filter((x)=>x.category===cat);
    setFilter(updatedList);
}

const ShowProducts =()=>{
    return(
        <>
        {filter.map((product,index)=>{
            return(
                <>
                <div className="col-md-3 mb-4">
                <div
                  className="card h-100 text-center border-light"
                  key={product.id}
                >
                  <NavLink to={`/product?idno=${product.id}`}>
                    {/* <img src={product.image} height="250px" className="card-img-top custom" alt={product.title}/> */}
                    <Container
                      className=""
                      ref={containerRef}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onMouseMove={handleMouseMove}
                      style={{ margin: "0px", padding: "0px" }}
                    >
                      <Image
                        ref={sourceRef}
                        height="250px"
                        className="card-img-top custom"
                        alt={product.title}
                        source={product.image}
                      />
                      <Target
                        ref={targetRef}
                        className="card-img-top custom"
                        alt="target"
                        name={product.title}
                        id={product.title}
                        opacity={opacity}
                        height="250px"
                        offset={offset}
                        source={product.secimage}
                      />
                    </Container>
                  </NavLink>
                  <div style={{ backgroundColor: "green", color: "white" }}>
                    <span>Next Day Delivery</span>
                  </div>
                  <div className="card-body">
                    <p>{product.title}</p>
                    {/* <p>{product.title.substring(0,50)}...</p> */}
                    <h5 className="card-text">{product.price} Birr</h5>

                    <button
                      onClick={() =>
                        deductProductCount(
                          product.id,
                          product.numberOfItem,
                          index
                        )
                      }
                      className="btn m-0 p-0 count-prod-btn"

                      style={{ width: "10%", height: "22%",fontSize:"16px",backgroundColor:"#efeff5" }}>

                      -              
                            </button>
                    <span className="fw-bold mx-1">{product.numberOfItem}</span>
                    <button
                      onClick={() =>
                        addProductCount(product.id, product.numberOfItem, index)
                      }
                      className="btn m-0 p-0 count-prod-btn"
                      style={{ width: "10%", height: "22%",fontSize:"16px",backgroundColor:"#efeff5" }}>
                      +
                    </button>
                    <button
                      className="btn add-prod-btn px-4 py-2 mx-2 border"
                      onClick={() => addProduct(product)}
                    >
                      {" "}
                      Add to Cart
                    </button>
                  </div>
                </div>
                </div>
                </>
            );

        })}
        </>
    );
}
  return (
    <div>
      <div className="container mb-5 py-5">
        {/* <div className="row">
          <div className="col-12 mb-5">
            <h1 className="display-6 fw-bold text-center">Latest Products</h1>
          </div>
        </div> */}
        <div className="row justify-content-center">
          {/* {loading?<Loading/>:<ShowProducts/>} */}
          {<ShowProducts/>}
        </div>
      </div>
    </div>
  );
};

export default Desserts;
