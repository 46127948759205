import React, { useContext, createContext } from "react";
import { BrowserRouter as Router, Routes, Route,Navigate } from "react-router-dom";
import Home from "../../home/Index";
import Header from "./Header";
import Footer from "../Footer";
import Cart from "../../cart";
import About from "../../About";
import Products from "../../product/Products";
import ProductDeatil from "../../product/ProductDetail";
import Desserts from "../../product/Desserts";
import Checkout from "../../product/checkout";
const userContext = createContext({ isLogedIn: false });
const isloginn = localStorage.getItem("isUserLogedIn");

const EvisaRouter = () => {
  const { isLogedIn } = useContext(userContext);
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/products" element={<Products />}></Route>
          <Route exact path="/desserts" element={<Desserts />}></Route>
          <Route exact path="/about" element={<About />}></Route>
          <Route exact path="/product" element={<ProductDeatil />}></Route>
          <Route exact path="/cart" element={<Cart />}></Route>
          <Route exact path="/checkout" element={<Checkout />}></Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default EvisaRouter;
