import React, { useState, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import headerImage from "./../.././../assets/Images/evisa-logo.png";
import headerLogo from "./../.././../assets/Images/logo.png";
import headerMobileImage from "./../.././../assets/Images/evisa-logo-mobile.png";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import EditIcon from "@mui/icons-material/Edit";
import ListIcon from "@mui/icons-material/List";
import Divider from "@mui/material/Divider";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AppsIcon from "@mui/icons-material/Apps";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PasswordIcon from "@mui/icons-material/Password";
import LogoutIcon from "@mui/icons-material/Logout";
// import { ConfirmDialog } from "primereact/confirmdialog";
// import CompanyService from "../../../service/company/CompanyService";
// import SnipperModal from "../../common/modal/snipperModal";
import { useNavigate } from "react-router-dom";
// import { Toast } from "primereact/toast";
import "./../../../assets/css/header.css";
import {useSelector} from 'react-redux'
// const companyData = localStorage.getItem("companyLoginResponse");
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [visible, setVisible] = useState(false);
  let [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useRef(null);
const state=useSelector((state)=>state.handleCart);
  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const confirmPosition = (position) => {
    setVisible(true);
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const [anchorEll, setAnchorEll] = React.useState(null);

  function handleClickk(event) {
    if (anchorEll !== event.currentTarget) {
      setAnchorEll(event.currentTarget);
    }
  }

  function handleClosee() {
    setAnchorEll(null);
  }


  const handleLogout = () => {
    localStorage.removeItem("companyLoginResponse");
    localStorage.removeItem("isUserLogedIn");
    localStorage.removeItem("userLoginRes");
    localStorage.removeItem("companyProfileRes");
    localStorage.removeItem("selectedmenu");
    navigate("/");
    window.location.reload();
  };
  return (
    <>
    <div className="container-fluid bg-light px-0">
      <div  style={{backgroundColor:"#804d00"}}>
        <div className="row m-0">
        <div className="col-md-1"></div>

        <div className="col-md-7">
          <marquee behavior="scroll" className="text-white" direction="left">Dear valued customers, due to some operational inconveniences, our delivery is limited only to a few locations for the time being. However, the outlet pick-up option is available. We expect to resolve this issue soon. Thank you.</marquee>
          </div>
          <div className="col-md-4">
          <ul class="nav justify-content-end">
      
      <li class="nav-item header-phone-n">
    
        <a href="tel:+251940404040" class="nav-link text-white active"><b><i className="fa fa-phone"></i> 0940404040</b></a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-white" href="#"><b><i className="fa fa-info-circle"></i>Need Help?</b></a>
      </li>
      <li class="nav-item">
      <Link to="/cart" className="btn ms-2 cart-btn" style={{backgroundColor:"#cc8500",color:"white"}}><i className="fa fa-shopping-cart me-1"></i>Cart({state.length})</Link>
      </li>
      <li class="nav-item mx-4">
      </li>
    </ul>
          </div>
        </div>

      </div>
      
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
    <Container>
      <Navbar.Brand href="#home">
        
      <img width={90} src={headerLogo}/>
        </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
        <ul className="navbar-nav me-auto mb-lg-0">
            <li className="nav-item">
                  <Link className="nav-link text-dark" to="/">Home</Link>
              </li>
              <li className="nav-item">
              <div className="dropdownn">
  {/* <Link to="#" className="dropbtn nav-link text-dark dropdown-toggle" data-toggle="dropdown">Menus</Link> */}
  <Link to="#" className="dropbtn nav-link text-dark">Menus</Link>
  <div className="dropdownn-content">
  <div className="row">
        <div className="col-6">
          <span className="px-2"><b>REGULAR PRODUCT</b></span>
          <a href="#">Bread</a>
        <a href="#">Pastery</a>
        <a href="#">Cookies</a>
        <a href="#">Desserts</a>
        <a href="#">Beverage</a>
          </div>
          <div className="col-6">
          <span><b>CAKES</b></span>
          <a href="#">Chocolate Cakes</a>
          <a href="#">Black Forest Cakes</a>
          <a href="#">Vanilla Cakes</a>
          <a href="#">Cheese Cakes</a>
          <a href="#">Pandan Cakes</a>
          </div>
        </div>
  </div>
  </div>
</li>
  <li className="nav-item">

  <div className="dropdownn">
  <Link to="#" className="dropbtn nav-link text-dark">Cake By Flavor</Link>
  <div className="dropdownnshort-content">
          <a href="#">Chocolate Cakes</a>
          <a href="#">Black Forest Cakes</a>
          <a href="#">Vanilla Cakes</a>
          <a href="#">Cheese Cakes</a>
          <a href="#">Pandan Cakes</a>
  </div>
  </div>

  </li>
<li className="nav-item">

<div className="dropdownn">
  <Link to="#" className="dropbtn nav-link text-dark">Customized Cakes</Link>
  <div className="dropdownnshort-content">
          <a href="#">Heart Shape Cakes</a>
          <a href="#">Photo Cakes</a>
          <a href="#">Kids Cakes</a>
  </div>
  </div>
           </li>


              <li className="nav-item">
                  <Link className="nav-link text-dark" to="/desserts">Desserts</Link>
              </li>
              <li className="nav-item">
                  <Link className="nav-link text-dark" to="/about">About</Link>
              </li>
            </ul>
        </Nav>
        <Nav>
              <ul className="navbar-nav me-auto mb-lg-0">
            <li className="nav-item">
              <Link to="/" className="nav-link text-dark"><i className="fa fa-user me-1"></i> Login &nbsp; |</Link>
                </li>
                <li className="nav-item">
              <Link to="/" className="nav-link text-dark">
                <i class="fa fa-user-plus"></i> Register
                </Link>
                </li>
                </ul>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
    </div>
    </>
  );
};

export default Header;
