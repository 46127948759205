import React  from "react";


const About =()=>{
    return(
        <>
        
        <h4>This Is About</h4>
        </>
    );

}


export default About